#info {
  display: flex;
  flex-direction: row;
  .background-blue {
    margin-top: -5px;
    flex-basis: 5vw;
    background-color: $body-color;
    @include media-breakpoint-up(sm) {
      flex-basis: 13vw;
    }
  }
  .cont {
    flex: 1;
    background-color: #f0f0f0;
    .info-box {
      display: flex;
      flex-direction: column;
      margin-bottom: 10vw;
      padding-left: 6vw;
      padding-right: 6vw;
      @include media-breakpoint-up(sm) {
        flex-direction: row;
        justify-content: center;
        margin-bottom: 5vw;
        gap: 100px;
        width: 100%;
        padding-left: 13vw;
      }
      @include media-breakpoint-up(lg) {
        gap: 300px;
      }
      .left {
        @include media-breakpoint-up(sm) {
          border-right: solid 1px $black;
        }
        .midashi1 {
          color: $black;
          text-align: center;
          @include media-breakpoint-up(sm) {
            writing-mode: vertical-rl;
            padding-right: 10px;
            text-align: start;
            letter-spacing: 0.2em;
          }
        }
      }
      .right {
        .brandTable {
        }
      }
    }
    .gmap {
      margin-bottom: 10vw;
      @include media-breakpoint-up(sm) {
        display: block;
        margin-bottom: 5vw;
        text-align: end;
      }
    }
    .instagram {
      padding-left: 6vw;
      padding-right: 6vw;
    }
  }
}
