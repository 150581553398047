.lineup-box {
  color: $white;
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 10vw;
  .lineup {
    display: flex;
    flex-direction: column;
    padding: 5px 10px;
    border-left: solid 1px $white;
    writing-mode: vertical-rl;
    text-orientation: upright;
    @include media-breakpoint-up(sm) {
      padding: 10px 5px;
    }
    @include media-breakpoint-up(lg) {
      padding: 15px 20px;
    }
    &:last-child {
      border-left: none;
    }
    .title {
      margin: 0;
      font-size: 13px;
      font-weight: 500;
      text-align: start;
      @include media-breakpoint-up(sm) {
        font-size: 20px;
      }
      @include media-breakpoint-up(lg) {
        font-size: 25px;
      }
    }
    .price {
      margin: 0;
      font-size: 11px;
      text-align: end;
      @include media-breakpoint-up(sm) {
        font-size: 15px;
      }
      @include media-breakpoint-up(lg) {
        font-size: 18px;
      }
      .number {
        font-size: 13px;
        font-weight: 500;
        @include media-breakpoint-up(sm) {
          font-size: 20px;
        }
        @include media-breakpoint-up(lg) {
          font-size: 25px;
        }
      }
      .tate-kakko {
        display: inline-block;
        transform: rotate(90deg);
      }
    }
  }
}
