#footer {
  background: url(../../../okonomiyaki-konamon/assets/img/footer.png) no-repeat;
  background-size: cover;
  background-position: center;
  padding: 5vw 0;
  .brandContainer {
    .cont {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      vertical-align: middle;
      gap: 10px;

      @include media-breakpoint-up(sm) {
        gap: 20px;
      }
      .logo {
        img {
        }
      }
      .gnav {
        list-style: none;
        margin-bottom: 30px;
        padding: 0;
        display: flex;
        flex-direction: column;
        gap: 10px;
        li {
          a {
            color: $white;
            text-decoration: none;
            font-size: 13px;
            &:hover {
              opacity: 0.5;
            }
          }
        }
      }
      .copyright {
        color: $white;
        margin: 0;
        font-size: 10px;
      }
    }
  }
}
