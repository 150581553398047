$headerHeight: 80px;
$headerHeightSM: 100px;
#header {
  .brandContainer {
    .group {
      position: absolute;
      z-index: 1;
      @include media-breakpoint-up(sm) {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 20vw;
      }
      .logo {
        img {
        }
      }
    }
  }
  .gnav {
    display: none;
    @include media-breakpoint-up(lg) {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 100px;
      list-style: none;
      margin: 20px 0 0;
      padding: 0;
      li {
        a {
          color: $white;
          text-decoration: none;
          &:hover{
            opacity: 0.5;
          }
        }
      }
    }
  }
}
