.brandTable {
  display: table;
  width: 100%;
  font-size: 14px;
  color: $black;
  @include media-breakpoint-up(sm) {
    font-size: 15px;
  }
  dl {
    display: table-row;
    margin: 0;
    padding: 0;
    dt {
      display: table-cell;
      margin: 0;
      padding: 20px 0;
      padding-right: 20px;
      white-space: nowrap;
      width: 1%;
      font-weight: normal;
    }
    dd {
      display: table-cell;
      margin: 0;
      padding: 20px 0;
      padding-left: 20px;
      @include media-breakpoint-up(sm) {
        padding-left: 100px;
      }
    }
  }
  &.responsive {
    @include media-breakpoint-down(sm) {
      display: block;
      dl {
        display: block;
        dt {
          display: block;
          padding: 15px 0;
          width: 100%;
        }
        dd {
          display: block;
          padding: 15px 0;
          width: 100%;
        }
      }
    }
  }
}
