.zoomupAnimation[data-trigger] {
  transform: scale(0);
  opacity: 0;
  transform-origin: center center;
  transition: 1s;
  &.InView {
    opacity: 1;
    transform: scale(1);
  }
}
.leftAnimation[data-trigger] {
  transform: translateX(10px);
  opacity: 0;
  transition: $transition-base;
  &.InView {
    transform: translateX(0);
    opacity: 1;
  }
}
.rightAnimation[data-trigger] {
  transform: translateX(-10px);
  opacity: 0;
  transition: $transition-base;
  &.InView {
    transform: translateX(0);
    opacity: 1;
  }
}
.topAnimation[data-trigger] {
  transform: translateY(10px);
  opacity: 0;
  transition: $transition-base;
  &.InView {
    transform: translateY(0);
    opacity: 1;
  }
}

.fadeInAnimation[data-trigger] {
  transition: $transition-base;
  animation-fill-mode: forwards;
  opacity: 0;
  &.InView {
    opacity: 1;
  }
}

