.ippin {
  .ippin-box {
    background: url(../../../okonomiyaki-konamon/assets/img/menu-bg.png);
    margin: 0 6vw 10vw;
    padding: 6vw;
    .midashi1 {
      color: $black;
    }
    // .first {
    //   display: flex;
    //   flex-direction: column;
    //   @include media-breakpoint-up(sm) {
    //     flex-direction: row-reverse;
    //     justify-content: center;
    //     align-items: center;
    //     gap: 5vw;
    //   }
    //   .menu-digest {
    //     // @include media-breakpoint-up(sm) {
    //     //   flex-basis: 80%;
    //     // }
    //   }
    //   .lineup-box {
    //     // @include media-breakpoint-up(sm) {
    //     //   flex: 1;
    //     // }
    //     .lineup {
    //       color: $black;
    //       border-left: solid 1px $black;
    //       &:last-child {
    //         border-left: none;
    //       }
    //     }
    //   }
    // }
    // .second {
    //   display: flex;
    //   flex-direction: column-reverse;
    //   @include media-breakpoint-up(sm) {
    //     flex-direction: row-reverse;
    //     justify-content: center;
    //     align-items: center;
    //     gap: 5vw;
    //   }
    //   @include media-breakpoint-up(lg) {
    //     gap: 10vw;
    //   }
    //   .lineup-box {
    //     .lineup {
    //       color: $black;
    //       border-left: solid 1px $black;
    //       &:last-child {
    //         border-left: none;
    //       }
    //     }
    //   }
    //   .menu-digest {
    //   }
    // }
  }
}
