.openday {
  position: fixed;
  right: 15px;
  bottom: 12px;
  z-index: 1000;
  cursor: pointer;
  opacity: 0;
  transition: $transition-base;
  img {
  }
  &.scrolled {
    opacity: 1;
  }
}
.modal-body {
  .tel {
    background-color: $body-color;
    padding: 30px 0;
    margin-bottom: 30px;
    @include media-breakpoint-up(sm) {
      margin-bottom: 50px;
    }
    p {
    }
    .whitebox {
      background-color: $white;
      max-width: 250px;
      padding: 12px 0;
      margin: 0 auto;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 10px;
      svg {
      }
      .telnumber {
        color: $body-color;
        margin: 0;
      }
    }
  }
  .monthimg {
    p {
    }
    .postgroup {
      display: flex;
      flex-direction: column;
      @include media-breakpoint-up(sm) {
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 30px;
      }
      .first-post {
      }
      .second-post {
      }
    }
  }
}
