#concept {
  background-color: #f0f0f0;
  margin-top: -20vw;
  padding-top: 30vw;
  .brandContainer {
    .digest {
      display: flex;
      flex-direction: column;
      gap: 30px;
      @include media-breakpoint-up(sm) {
        flex-direction: row-reverse;
        gap: 5vw;
      }

      .img {
        @include media-breakpoint-up(sm) {
          flex-basis: 60%;
        }
        img {
        }
      }
      .cont {
        writing-mode: vertical-rl;
        color: $black;
        margin: 0 auto;
        line-height: 2.5em;
        @include media-breakpoint-up(sm) {
          flex: 1;
          margin-top: 20vw;
        }
        .text {
          margin: 0;
          letter-spacing: 0.2em;
          @include media-breakpoint-up(sm) {
            font-size: 20px;
          }
        }
      }
    }
  }
}
