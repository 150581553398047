.topping {
  .brandContainer {
    .midashi1 {
    }
    .img {
      text-align: center;
      padding: 0 5vw;
      @include media-breakpoint-up(sm) {
        padding: 0 10vw;
      }
    }
  }
}
