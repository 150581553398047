.instagram {
  .midashi1 {
    color: $black;
  }
  .sp-SnapWidget {
    @include media-breakpoint-up(sm) {
      display: none;
    }
  }
  .pc-SnapWidget {
    display: none;
    @include media-breakpoint-up(sm) {
      display: block;
    }
  }
}
