.okonomiyaki {
  margin-bottom: 10vw;
  .brandContainer {
    .midashi1 {
    }
    .img{
      text-align: center;
    }
  }
}
