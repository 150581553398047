.midashi1 {
  padding-bottom: 50px;
  margin: 0;
  font-size: 30px;
  font-weight: bold;
  color: $white;
  text-align: center;
  @include media-breakpoint-up(sm) {
    padding-bottom: 80px;
    font-size: 50px;
  }
}
