#news {
  background-color: $body-color;
  .brue-box {
    padding: 2vw;
    @include media-breakpoint-up(sm) {
      padding: 2vw 10vw;
    }
    .box {
      background-color: $white;
      padding: 1vw;
      .news-box {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        color: $black;
        transition: 0s;
        border-bottom: solid 1px $black;
        text-decoration: none;
        margin: 0 5vw 10px;
        font-size: 13px;
        padding: 0 5vw;
        @include media-breakpoint-up(sm) {
          flex-direction: row;
          gap: 100px;
          font-size: 13px;
        }
        @include media-breakpoint-up(lg) {
          padding: 0 8vw;
          font-size: 15px;
        }
        &:hover {
          color: rgba($color: #000000, $alpha: 0.3);
        }
        .day {
          border: none;
          margin: 0;
          padding-bottom: 2px;
        }
        .title {
          border: none;
          margin: 0;
          padding-bottom: 5px;
        }
      }
    }
  }
}
