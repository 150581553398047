#hero {
  width: 100%;
  video {
    max-width: 100%;
    max-height: 100%;
    vertical-align: bottom;
    @include media-breakpoint-up(sm) {
      min-width: 100%;
      min-height: 100%;
    }
  }
}
