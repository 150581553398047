.menu-digest {
  position: relative;
  margin-bottom: 20vw;
  @include media-breakpoint-up(sm) {
    margin-top: 5vw;
    margin-bottom: 10vw;
  }
  .img {
    position: relative;
    text-align: center;
    img {
      width: 100%;
    }
  }
  .cont {
    position: absolute;
    bottom: -30%;
    right: 0;
    max-width: 50vw;
    background-color: #f0ecdc;
    padding: 10px;
    @include media-breakpoint-up(sm) {
      bottom: -10%;
    }
    @include media-breakpoint-up(lg) {
      padding: 20px;
      max-width: auto;
      bottom: auto;
      top: -20%;
      right: -5vw;
      max-height: 40vw;
    }

    .text {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      border: solid 1px $black;
      padding: 5px 10px;
      color: $black;
      @include media-breakpoint-up(lg) {
        writing-mode: vertical-rl;
        text-orientation: upright;
        padding: 15px 20px;
      }
      .title {
        margin: 0;
        font-size: 15px;
        font-weight: 500;
        @include media-breakpoint-up(lg) {
          font-size: 25px;
        }
      }
      .price {
        margin: 0;
        font-size: 13px;
        @include media-breakpoint-up(lg) {
          font-size: 18px;
        }
        .number {
          font-size: 15px;
          font-weight: 500;
          @include media-breakpoint-up(lg) {
            font-size: 25px;
          }
        }
        .tate-kakko {
          @include media-breakpoint-up(lg) {
            display: inline-block;
            transform: rotate(90deg);
          }
        }
      }
    }
  }
}
