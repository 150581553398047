.modal {
  color: $black;
  .modal-content {
    background-color: #F0F0F0;
    .modal-header {
      border-bottom: none;
      .modal-title {
        font-size: 20px;
        font-weight: bold;
        @include media-breakpoint-up(sm) {
          font-size: 25px;
        }
      }
    }
    .modal-body {
      font-size: 15px;
    }
  }
}
