#view {
  background-color: $body-color;
  .brandContainer {
    .midashi1 {
    }
    .view-box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 20px;
      @include media-breakpoint-up(sm) {
        flex-direction: row;
      }
      .view-digest {
        text-align: center;
        .img {
          margin-bottom: 20px;
          img {
          }
        }
        .cont {
          p {
            color: $white;
            margin: 0;
          }
        }
      }
    }
  }
}
